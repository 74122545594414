import React from "react";
import "./css/App.scss";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import { SISFooter, SISNavigation } from "./components";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { common, green } from "@mui/material/colors";
import AboutUs from "./pages/aboutUS";
import WhatWeDo from "./pages/whatWeDo/whatWeDo";
import Contact from "./pages/contact";

const theme = createTheme({
  palette: {
    primary: {
      main: common.white,
      contrastText: "#628699",
    },
    secondary: {
      main: "#9ea2aF",
      light: "#F2F2F3",
      dark: "#1D2226",
    },
  },
});

function App() {
  return (
    <>
      <Router>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/what-we-do" element={<WhatWeDo />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <SISFooter />
        </ThemeProvider>
      </Router>
    </>
  );
}

export default App;
