import React, { useState, useEffect } from "react";

import styles from "./SISFullWidthCard.module.scss";

export interface SISFullWidthCardProps {
  children?: JSX.Element;
  title?: string | null;
  bodyText?: string;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5";
  htmlText?: string;
  style?: any;
  className?: string;
  id?: string;
}

export function SISFullWidthCard({
  children,
  title,
  bodyText,
  variant = "h1",
  htmlText,
  style,
  className,
  id,
}: SISFullWidthCardProps) {
  const [bodyTextColor, setBodyColorText] = useState({});

  const html = `<${variant}>${title} </${variant}>`;

  const showHtmlText = htmlText ? (
    <p
      dangerouslySetInnerHTML={{ __html: htmlText }}
      className={styles.bodyText}
    ></p>
  ) : (
    <></>
  );

  return (
    <div
      className={`${styles.className} ${styles.FWCcontainer} `}
      style={style}
      id={id}
    >
      {title ? (
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          className={styles.title}
        ></div>
      ) : (
        <></>
      )}

      <div className={styles.bodyText} style={bodyTextColor}>
        {bodyText}
      </div>
      <div className={styles.bodyText} style={bodyTextColor}>
        {showHtmlText}
      </div>
    </div>
  );
}
