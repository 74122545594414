import React from "react";
import styles from "./whatWeDo.module.scss";
import bgImage from "../../assets/marble.jpg";
import { MainLayout } from "../../layout/MainLayout";
import { SISFullWidthCard } from "../../components";
import { Row } from "react-bootstrap";

export interface WhatWeDoProps {
  prop?: string;
}

function WhatWeDo({ prop = "default value" }: WhatWeDoProps) {
  const wwdText = `Shine It Systems are specialists in the burnishing, maintenance and restoration of concrete surfaces. The breadth and depth of our experience with concrete surfaces ensures that we understand how every surface is unique and are able to provide you with professional and practical solutions. Our skill and experience ensures that you are able to have the beautiful surfaces you desire in a practical and efficient way.
  <br/> <br/> The processes of burnishing, maintaining and restoration of concrete to achieve beautiful surfaces requires a high level of skill and attention to detail. The final finish of the surface is the direct result of a number of variables. The variables that we can control are the quality of equipment and our expertise in operating the equipment. Variables such as the porosity and level of the surface along with its history of cleaners and sealers are outside of our control, but need to be carefully considered when consulting so that we are able to acheive a beautiful and functional surface.
  <br/> <br/> Knowing how to work within the constraints of these variables is the difference between a true craftsman who takes pride in the details and the final result and an everyday contractor who simply goes through the motions of finishing the job.
  <br/> <br/> We are also accredited in the application of Dry Treat Stain-Proof and Lithofin Stain-Stop.  Application of these products by an accredited applicator helps prevents staining, simplifies maintenance and guarantees the longevity of your surfaces and provides you with warranties for up to 20 years of protection.
  With our specialised knowledge and equipment we bring your surfaces to life.  Call us on 0411 413 380 to discuss your requirements. `;

  return (
    <MainLayout title="What We Do" bgImage={bgImage} headerTextColor={"light"}>
      <>
        <Row>
          <SISFullWidthCard htmlText={wwdText} className="card" id="darkCard" />
        </Row>
      </>
    </MainLayout>
  );
}

export default WhatWeDo;
