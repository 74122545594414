import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import waterMark from "../../assets/Wordmark-final.png";

const useStyles: any = makeStyles((theme: any) => ({
  container: {
    width: 250,
    marginTop: 20,
  },
  link: {
    textDecoration: "none",
    fontSize: "20px",
    color: "black",
  },
  icon: {
    color: "white",
  },
}));

export function SISDrawer() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  let activeStyle = { color: "#628699" };

  return (
    <>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <img
          src={waterMark}
          alt="shineit logo"
          style={{
            height: 85,
            width: 180,
            marginTop: 25,
            marginLeft: 20,
            marginBottom: 50,
          }}
        />
        <List className={classes.container}>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavLink
                to="/"
                className={` ${classes.link}`}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                end
              >
                Home
              </NavLink>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavLink
                to="/about-us"
                className={classes.link}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                end
              >
                About Us
              </NavLink>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavLink
                to="/what-we-do"
                className={classes.link}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                end
              >
                What We Do
              </NavLink>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavLink
                to="/our-past-projects"
                className={classes.link}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                end
              >
                Our Past Projects
              </NavLink>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavLink
                to="/testimonials"
                className={classes.link}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                end
              >
                Testimonials
              </NavLink>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavLink
                to="/contact"
                className={classes.link}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                end
              >
                Contact
              </NavLink>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <IconButton
        aria-label="Menu Icon"
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <FontAwesomeIcon icon={faBars} />
      </IconButton>
    </>
  );
}
