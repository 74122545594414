import React from "react";

import styles from "./SISFooter.module.scss";

export interface SISFooterProps {
  prop?: string;
}

export function SISFooter({ prop = "default value" }: SISFooterProps) {
  return <div className={styles.SISFooter}>SISFooter {prop}</div>;
}
