import React, { useState } from "react";
import { SISCarousel } from "../../components";
import { useMediaQuery } from "@mui/material";
import marble from "../../assets/marble.jpg";
import stone from "../../assets/stone.jpeg";
// import wall from "../../assets/wall.jpg";
import white from "../../assets/white.jpg";
// import background from "../../assets/cat-han-W_5Eakb1598-unsplash.jpg";
import styles from "./Home.module.scss";
import { SISFullWidthCard } from "../../components/SISFullWidthCard";
import { Row } from "react-bootstrap";
import { MainLayout } from "../../layout/MainLayout";

function Home() {
  // const isXLarge = useMediaQuery((theme: any) => theme.breakpoints.down("xl"));
  // const isLarge = useMediaQuery((theme: any) => theme.breakpoints.down("lg"));
  // const isMedium = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const [hide, setHide] = useState(false);

  var items = [
    {
      title: "Natural Stone",
      subTitle: "We specialise in all nature and engineered stone",
      src: marble,
    },
    {
      title: "Random Name #2",
      subTitle: "Hello World!",
      src: stone,
    },
    {
      title: "Random Name #2",
      subTitle: "Hello World!",
      src: white,
    },
  ];

  const aboutText = ` Shine It Systems is a small and specialised family business run by Glen, Jo and Scott Dunstan.  Their focus is on making concrete surfaces look and feel beautiful again and again and again, or for the very first time.
  <br/> <br/>  Shine It Systems evolved from a floor cleaning business almost 25 years ago that became so successful it employed 35 full time staff and serviced a number of major supermarket and retail chains. Whilst the business was rewarding, Glen became increasingly frustrated by the lack of knowledge and experience of those working in the field. He realised that no one in the industry specialised in flooring and that very few in Perth truly understood how the products used to clean, restore and seal surfaces interacted with the unique nature of each surface. Since this realisation, Glen has dedicated himself and the business to an ongoing learning journey in trialling products and techniques in order to achieve the results that clients are really looking for.  Glen also spends time working closely with chemists and manufacturers to develop new products and techniques in order to enhance and protect your surfaces.
  <br/> <br/> Shine It Systems is in the unique position of understanding the vast array of products available on the market and how to apply those products to get the very best results for each unique surface.  This, combined with the teams attention to detail and strong customer focus ensures that they achieve exactly the results that clients want.
  <br/> <br/>  Shine It Systems is continually investing in new equipment and techniques ensuring that the process of burnishing and restoring surfaces gives you the best results with as little interruption as possible.
  <br/> <br/>  We know that one solution does not suit every situation. Every surface is unique, and we do everything possible to create strong and durable floor surfaces that are aesthetically pleasing and beautiful to be near. The proof is in the photos on this website, all of which demonstrate the pride that the Shine It Systems team take in their attention to creating beautiful surfaces.`;

  const wwdText = `Shine It Systems are specialists in the burnishing, maintenance and restoration of concrete surfaces. The breadth and depth of our experience with concrete surfaces ensures that we understand how every surface is unique and are able to provide you with professional and practical solutions. Our skill and experience ensures that you are able to have the beautiful surfaces you desire in a practical and efficient way.
<br/> <br/> The processes of burnishing, maintaining and restoration of concrete to achieve beautiful surfaces requires a high level of skill and attention to detail. The final finish of the surface is the direct result of a number of variables. The variables that we can control are the quality of equipment and our expertise in operating the equipment. Variables such as the porosity and level of the surface along with its history of cleaners and sealers are outside of our control, but need to be carefully considered when consulting so that we are able to acheive a beautiful and functional surface.
<br/> <br/> Knowing how to work within the constraints of these variables is the difference between a true craftsman who takes pride in the details and the final result and an everyday contractor who simply goes through the motions of finishing the job.
<br/> <br/> We are also accredited in the application of Dry Treat Stain-Proof and Lithofin Stain-Stop.  Application of these products by an accredited applicator helps prevents staining, simplifies maintenance and guarantees the longevity of your surfaces and provides you with warranties for up to 20 years of protection.
With our specialised knowledge and equipment we bring your surfaces to life.  Call us on 0411 413 380 to discuss your requirements. `;

  return (
    <MainLayout>
      <>
        <div style={{ height: 120 }}></div>
        <div className={styles.container}>
          <div
            className={styles.carousel}
            style={{ height: isMobile ? "70vh" : " 85vh" }}
          >
            <SISCarousel items={items} hide={hide} />
          </div>
          <div className={styles.cardContainer}>
            <Row>
              <SISFullWidthCard
                title={"ABOUT US"}
                htmlText={aboutText}
                className="card"
                id="lightCard"
              />
            </Row>
            <Row style={{ backgroundColor: "#1d2226", color: "white" }}>
              <SISFullWidthCard
                title={"WHAT WE DO"}
                htmlText={wwdText}
                className="card"
                id="darkCard"
              />
            </Row>
          </div>
        </div>
      </>
    </MainLayout>
  );
}

export default Home;
