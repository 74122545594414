export const calcDimensions = (number1: any, number2: any) => {
  let num = number1.match(/\d+/g);
  let letr = number1.match(/[a-zA-Z]+/g);
  let dimension = num - number2;
  let string = dimension.toString();
  let final = string + letr;
  return final;
};

export const empty = (str?: string | null, replace: string = "") => {
  if (str) {
    return str.length > 0 ? str.trim() : replace;
  }
  return replace;
};
