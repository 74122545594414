import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import marble from "../../assets/marble.jpg";
import stone from "../../assets/stone.jpeg";
import wall from "../../assets/wall.jpg";
import white from "../../assets/white.jpg";
import styles from "./SISCarousel.module.scss";
import { useMediaQuery } from "@mui/material";
import { calcDimensions } from "../../utils/utils";
export interface SISCarouselProps {
  items?: {
    title?: string | null;
    subTitle?: string | null;
  }[];
  className?: string;
  hide?: boolean;
}
export function SISCarousel({
  items,
  className,
  hide = false,
}: SISCarouselProps) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };
  const [imgSize, setImgSize] = useState({ height: "75vh", width: "65vh" });
  const isXLarge = useMediaQuery((theme: any) => theme.breakpoints.down("xl"));
  const isLarge = useMediaQuery((theme: any) => theme.breakpoints.down("lg"));
  const isMedium = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      className={styles.container}
      style={{
        display: hide ? "none" : "",
      }}
    >
      {items?.map((item: any) => {
        return (
          <Carousel.Item key={item.src} className={styles.item}>
            <div className={styles.imgContainer}>
              <img
                id={styles.imgLeft}
                className={styles.img}
                style={{
                  height: calcDimensions(imgSize.height, 10),
                  width: imgSize.width,
                }}
                src={item.src}
                alt="First slide"
              />
              <img
                id={styles.middle}
                className={styles.img}
                style={{
                  height: isMobile
                    ? calcDimensions(imgSize.height, 20)
                    : imgSize.height,
                  width: isMobile
                    ? calcDimensions(imgSize.width, 20)
                    : imgSize.width,
                }}
                src={item.src}
                alt="First slide"
              />
              <img
                id={styles.imgRight}
                className={styles.img}
                style={{
                  height: calcDimensions(imgSize.height, 10),
                  width: imgSize.width,
                }}
                src={item.src}
                alt="First slide"
              />
            </div>
            <Carousel.Caption>
              <h3>{item.title}</h3>
              <p>{item.subTitle}</p>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}
