import React from "react";
import styles from "./contact.module.scss";
import bgImage from "../../assets/esp.jpg";
import { MainLayout } from "../../layout/MainLayout";
import { SISFullWidthCard, SISHalfCard } from "../../components";
import { Row, Col } from "react-bootstrap";
import { FormControl, InputLabel, Input, FormHelperText } from "@mui/material";

export interface ContactProps {
  prop?: string;
  text?: string;
}

const Contact = ({ text }: ContactProps) => {
  const handleFormSubmit = () => {
    console.log("Submitted");
  };
  return (
    <MainLayout title="Contact US" bgImage={bgImage} headerTextColor={"light"}>
      <Row className={styles.container}>
        <Col className={styles.left}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </Col>
        <Col className={styles.right}>
          <form onSubmit={handleFormSubmit}>
            <FormControl>
              <InputLabel htmlFor="my-input">Email address</InputLabel>
              <Input id="my-input" aria-describedby="my-helper-text" />
              <FormHelperText id="my-helper-text">
                We'll never share your email.
              </FormHelperText>
            </FormControl>
          </form>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default Contact;
