import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SpacingOptions } from "../../props/AppDataProps";
import { empty } from "../../utils/utils";

import styles from "./SISHeader.module.scss";
import { useEffect, useState } from "react";

export interface CBHHeaderProps {
  variant?: "h2" | "h3" | "h4" | "h5" | "h6";
  title: string;
  hashId?: string;
  text?: string;
  htmlText?: string;
  useParagraphTag?: boolean;
  icon?: IconProp;
  iconSize?: SizeProp;
  hideHelper?: boolean;
  useButton?: boolean;
  className?: string;
  saveComplete?: boolean;
  marginTop?: SpacingOptions;
  marginBottom?: SpacingOptions;
  textAlign?: "left" | "center" | "right";
  children?: JSX.Element;
  headerTextColor?: "dark" | "light" | "primary" | null;
}

export function SISHeader({
  variant,
  title,
  hashId,
  text,
  htmlText,
  useParagraphTag,
  icon,
  iconSize,
  hideHelper,
  useButton = true,
  className,
  saveComplete,
  marginTop = "0",
  marginBottom = "4",
  textAlign = "left",
  children,
  headerTextColor,
}: CBHHeaderProps) {
  const [textColor, setTextColor] = useState("black");

  useEffect(() => {
    switch (headerTextColor) {
      case "dark":
        setTextColor(styles.dark);
        break;
      case "light":
        setTextColor(styles.light);
        break;
      case "primary":
        setTextColor(styles.primary);
        break;
    }
  }, []);
  const showIcon = icon ? (
    <FontAwesomeIcon className="me-3" icon={icon} size={iconSize} />
  ) : (
    <></>
  );
  const showAnchor = hashId ? (
    <div id={hashId} className={styles.offset}></div>
  ) : (
    <></>
  );

  const showText =
    text && text.length > 0 ? (
      <p className="mt-3" style={{ textAlign }}>
        {text}
      </p>
    ) : (
      <></>
    );
  const showHtmlText = htmlText ? (
    <div className="mt-3" dangerouslySetInnerHTML={{ __html: htmlText }}></div>
  ) : (
    <></>
  );
  if (useParagraphTag) {
    return (
      <div
        className={`${
          styles.container
        } mb-${marginBottom} mt-${marginTop} ${empty(className)}`}
      >
        {showAnchor}
        <div className="d-flex align-items-center">
          {showIcon}
          <p className={`mb-0 ${empty(variant, "h1")} `} style={{ textAlign }}>
            {title}
          </p>

          {children}
        </div>
        {showText}
        {showHtmlText}
      </div>
    );
  }
  const html = `<${empty(variant, "h1")} class="mb-0 ${textColor} ${
    styles.title
  }" >${title}</${empty(variant, "h1")}>`;
  return (
    <div
      className={`${
        styles.container
      } mb-${marginBottom} mt-${marginTop} ${empty(className)}`}
    >
      {showAnchor}
      <div
        className="d-flex align-items-center"
        style={{ justifyContent: textAlign }}
      >
        {showIcon}
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          className={styles.title}
        ></div>

        {children}
      </div>
      {showText}
      {showHtmlText}
    </div>
  );
}
