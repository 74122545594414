import React from "react";
import styles from "./AboutUs.module.scss";
import bgImage from "../../assets/esp.jpg";
import { MainLayout } from "../../layout/MainLayout";
import { SISFullWidthCard, SISHalfCard } from "../../components";
import { Row } from "react-bootstrap";

export interface AboutUSProps {
  children?: string;
}

const AboutUs = ({ children }: AboutUSProps) => {
  const aboutText = ` Shine It Systems is a small and specialised family business run by Glen, Jo and Scott Dunstan.  Their focus is on making concrete surfaces look and feel beautiful again and again and again, or for the very first time.
  <br/> <br/>  Shine It Systems evolved from a floor cleaning business almost 25 years ago that became so successful it employed 35 full time staff and serviced a number of major supermarket and retail chains. Whilst the business was rewarding, Glen became increasingly frustrated by the lack of knowledge and experience of those working in the field. He realised that no one in the industry specialised in flooring and that very few in Perth truly understood how the products used to clean, restore and seal surfaces interacted with the unique nature of each surface. Since this realisation, Glen has dedicated himself and the business to an ongoing learning journey in trialling products and techniques in order to achieve the results that clients are really looking for.  Glen also spends time working closely with chemists and manufacturers to develop new products and techniques in order to enhance and protect your surfaces.
  <br/> <br/> Shine It Systems is in the unique position of understanding the vast array of products available on the market and how to apply those products to get the very best results for each unique surface.  This, combined with the teams attention to detail and strong customer focus ensures that they achieve exactly the results that clients want.
  <br/> <br/>  Shine It Systems is continually investing in new equipment and techniques ensuring that the process of burnishing and restoring surfaces gives you the best results with as little interruption as possible.
  <br/> <br/>  We know that one solution does not suit every situation. Every surface is unique, and we do everything possible to create strong and durable floor surfaces that are aesthetically pleasing and beautiful to be near. The proof is in the photos on this website, all of which demonstrate the pride that the Shine It Systems team take in their attention to creating beautiful surfaces.`;
  return (
    <MainLayout title="ABOUT US" bgImage={bgImage} headerTextColor={"light"}>
      <>
        <Row>
          <SISFullWidthCard
            htmlText={aboutText}
            className="card"
            id="darkCard"
          />
          <SISHalfCard text={aboutText} />
        </Row>
      </>
    </MainLayout>
  );
};

export default AboutUs;
