import React, { ReactElement, ReactHTMLElement } from "react";
import { SISHeader } from "../../components";
import { SISNavigation } from "../../components";
import styles from "./MainLayout.module.scss";
import { empty } from "../../utils/utils";

export interface MainLayoutProps {
  title?: string;
  children?: JSX.Element;
  bgImage?: string;
  headerTextColor?: "dark" | "light" | "primary" | null;
}

export function MainLayout({
  title = "",
  children,
  bgImage,
  headerTextColor,
}: MainLayoutProps) {
  const navItems = [
    "Home",
    "About Us",
    "What We Do",
    "Past Projects",
    "Testimonials",
    "Contact",
  ];
  return (
    <div>
      <SISNavigation navItems={navItems} />

      {title ? (
        <div className={styles.header}>
          <div
            className={styles.backgroundImage}
            style={{
              backgroundImage: empty(`url(${bgImage})`),
            }}
          ></div>
          <SISHeader title={title} headerTextColor={headerTextColor} />
        </div>
      ) : (
        <></>
      )}
      <div className={styles.body}>{children}</div>
    </div>
  );
}
