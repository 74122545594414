import logo from "../../assets/Final-plain-logo.png";
import waterMark from "../../assets/Wordmark-final.png";
import { makeStyles, useTheme } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  useMediaQuery,
  InputBase,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { SISDrawer } from "../SISDrawer";

export interface SISNavigationProps {
  navItems?: string[];
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#F2F2F3",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  marginTop: -5,
  width: "100%",
  [theme.breakpoints.up("md")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const useStyles: any = makeStyles((theme: any) => ({
  container: {
    height: 120,
    display: "flex",
    justifyContent: "center",
  },
  navItems: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "black",
    fontSize: "18px",
    marginLeft: theme.spacing(5),
    "&:hover": {
      color: "#628699",
      borderBottom: "1px solid white",
    },
  },
}));

let activeStyle = { color: "#628699" };

export function SISNavigation({ navItems }: SISNavigationProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isLarge = useMediaQuery((theme: any) => theme.breakpoints.down("lg"));
  const isMedium = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("xl"));

  // Everything but IE
  if (window.document.readyState == "loading") {
    console.log("Your page is loading");
  }

  // // IE
  // window.attachEvent("onload", function() {
  //   // loaded
  // });

  return (
    <AppBar
      position="static"
      className={classes.container}
      style={{ position: "fixed", zIndex: 2 }}
    >
      <CssBaseline />
      <Toolbar>
        <Typography variant="h4" className={classes.logo}>
          <img
            src={logo}
            alt="shineit logo"
            style={{ height: 80, width: 100 }}
          />
          {isMobile ? (
            <div></div>
          ) : (
            <img
              src={waterMark}
              alt="shineit logo"
              style={{
                height: 85,
                width: 180,
                marginTop: 10,
                marginLeft: 20,
              }}
            />
          )}
        </Typography>
        {isMobile ? (
          <SISDrawer />
        ) : (
          <div className={classes.navItems}>
            <NavLink
              to="/"
              className={` ${classes.link}`}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              end
            >
              Home
            </NavLink>
            <NavLink
              to="/about-us"
              className={classes.link}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              end
            >
              About Us
            </NavLink>
            <NavLink
              to="/what-we-do"
              className={classes.link}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              end
            >
              What We Do
            </NavLink>
            <NavLink
              to="/our-past-projects"
              className={classes.link}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              end
            >
              Our Past Projects
            </NavLink>
            <NavLink
              to="/testimonials"
              className={classes.link}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              end
            >
              Testimonials
            </NavLink>
            <NavLink
              to="/contact"
              className={classes.link}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              end
            >
              Contact
            </NavLink>
            {/* <SISContact /> */}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
