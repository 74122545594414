import React from "react";

import styles from "./SISHalfCard.module.scss";
import Image1 from "../../assets/sina-jahany-9vJJVjE8LIY-unsplash.jpg";
import { useMediaQuery } from "@mui/material";

export interface SISHalfCardProps {
  prop?: string;
  text?: string;
}

export function SISHalfCard({ text }: SISHalfCardProps) {
  const isXLarge = useMediaQuery((theme: any) => theme.breakpoints.down("xl"));
  const isLarge = useMediaQuery((theme: any) => theme.breakpoints.down("lg"));
  const isMedium = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <>
      {isMobile ? (
        <div className={styles.smContainer}>
          <div className={`${styles.smSecondaryContainer} ${styles.smLeft}`}>
            <div
              className={styles.smImage}
              style={{ backgroundImage: `url(${Image1})` }}
            ></div>
            <div className={styles.smL2Background}></div>
          </div>
          <div className={`${styles.smSecondaryContainer} ${styles.smRight}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="mb-0 ${styles.smParagraph}" >${text}</div>`,
              }}
            ></div>
          </div>
        </div>
      ) : isLarge ? (
        <div className={styles.lgContainer}>
          <div className={`${styles.lgSecondaryContainer} ${styles.lgLeft}`}>
            <div
              className={styles.lgImage}
              style={{ backgroundImage: `url(${Image1})` }}
            ></div>
            <div className={styles.lgL2Background}></div>
          </div>
          <div className={`${styles.lgSecondaryContainer} ${styles.lgRight}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="mb-0 ${styles.lgParagraph}" >${text}</div>`,
              }}
            ></div>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={`${styles.secondaryContainer} ${styles.left}`}>
            <div
              className={styles.image}
              style={{ backgroundImage: `url(${Image1})` }}
            ></div>
            <div className={styles.L2Background}></div>
          </div>
          <div className={`${styles.secondaryContainer} ${styles.right}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="mb-0 ${styles.paragraph}" >${text}</div>`,
              }}
            ></div>
          </div>
        </div>
      )}
    </>
  );
}
